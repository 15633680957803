<template>
  <div class="container">
    <div class="bg-yun-left"></div>
    <div class="bg-yun-right"></div>
    <div class="bg-top"></div>
    <div class="bg-center"></div>
    <div class="bg-bottom"></div>
    <div class="bg-shanzi"></div>
    <div class="bg-shade" v-if="!isNormal"></div>
    <div class="main-con" :style="{height: (innerHeight >680 ? '88%' : '90%') }">
      <div class="logo"></div>
      <!-- <div style="flex:1;"></div> -->
      <div class="big-tit"></div>
      <!-- <div style="flex:1;"></div> -->
      <div v-if="isNormal" class="small-tit"></div>
      <div v-else class="small-line"></div>
      <div class="cj-wrapper" v-if="cjShow">
        <div
          @click="handleClick(item,index)"
          v-for="(item,index) in cjList"
          :key="index"
          class="cj-card"
          :class=" [isNormal ? ['cinton'+(index+1)] : [item.move], really !== index+1 && !isNormal ? `${item.left}`:'']"
        >
          <div class="cj-text"></div>
          <div class="btn-click" v-if="isNormal"></div>
          <div class="cj-end" v-if="!isNormal && really == index+1 && item.amount != 0">
            <div v-if="flopStatus" class="zj-count">{{item.amount}}</div>
          </div>
          <div class="cj-end cj-zero" v-if="!isNormal && really == index+1 && item.amount == 0"></div>
          <div class="cj-other" v-if="!isNormal && really !== index+1">
            <div v-if="flopStatus" class="zj-count mar-bt">{{item.amount}}</div>
          </div>
        </div>
      </div>
      <div class="cj-hide" v-else>
        <p>该兑奖码未激活</p>
        <p>或已失效！</p>
      </div>
      <!-- <div style="flex:1;"></div> -->
      <div v-if="isNormal" class="btn-intro" @click="show = true"></div>
      <div v-else class="btn-look" @click="navToMain"></div>
    </div>
    <div id="map-container"></div>
    <van-overlay z-index="9999" :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block">
          <div class="close" @click="show = false"></div>
          <div class="p-intro"></div>
          <div class="p-intro-cont">
            <p>1.微信扫描包装袋内二维码，即可参与抽奖活动</p>
            <p>2.在页面任意选择一个红包，喜兑现金大奖</p>
          </div>
          <div class="p-intro-desc">*本活动最终解释权归湖南上意佳生物科技有限公司所有</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay z-index="9999" :show="imgShow" @click="imgShow = false">
      <div class="wrapper">
        <div class="img-block">
          <div class="ad-close" @click="imgShow = false"></div>
          <img class="ad-img" v-if="bannerUrl" :src="bannerUrl" alt="">
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { throttle } from "@/utils/index";
import Vue from "vue";
import { Dialog, Overlay } from "vant";
Vue.use(Overlay);
export default {
  name: "Index",
  data() {
    return {
      userInfo: null,
      innerHeight: 0,
      flopStatus: false,
      isNormal: true,
      really: "",
      whether: false,
      surplus: false,
      implement: 0,
      province: null,
      city: null,
      district: null,
      posLeft: ["left1", "left2", "left3"],
      cjShow: true,
      code: "",
      amountList: [],
      cjList: [
        {
          flop: "恭",
          implement: 0,
          surplus: false,
          amount: "0.1",
          left: 0
        },
        {
          flop: "喜",
          implement: 0,
          surplus: false,
          amount: "10.00",
          left: 50
        },
        {
          flop: "发",
          implement: 0,
          surplus: false,
          amount: "2.00",
          left: 100
        },
        {
          flop: "财",
          implement: 0,
          surplus: false,
          amount: "5.00",
          left: 150
        }
      ],
      imgShow: false,
      show: false,
      bannerUrl: null,
      amount: 0
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  created() {
    let openId = localStorage.getItem("openId");
    this.innerHeight = window.innerHeight;

    if (openId && openId != "") {
      this.userInfo = JSON.parse(localStorage.getItem("wxUserInfo"));
      this.$store.dispatch(
        "setUserInfo",
        JSON.parse(localStorage.getItem("wxUserInfo"))
      );
    }
    // console.log(this.$route.query.c)
    
  },
  mounted() {
    if (this.$route.query.c) {
      this.code = this.$route.query.c;
      this.getBarCode(this.$route.query.c);
    }
  },
  methods: {
    location(id) {
      let that = this;
      let mapObj = new AMap.Map("map-container");
      mapObj.plugin("AMap.Geolocation", function() {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        mapObj.addControl(geolocation);
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete); //返回定位信息
        function onComplete(result) {
          const { province, city, district } = result.addressComponent;
          that.updateAddress({province,city,district,id:id})
          //  alert(`${province}`)
        }
        function onError(data) {
          console.log("error", data);
          // alert('error')
        }
        AMap.event.addListener(geolocation, "error", onError); //返回定位出错信息
      });
    },
    handleClick: throttle( async function(item, index) {
      console.log('11111-------22222')
      if (!this.isNormal) {
        // this.navToMain();
        return;
      }
      try {
        //redPack/pocketMoney
        const { data } = await this.$api.getPrize({
          barcodeId: this.code,
          openId: this.userInfo.openId,
          payType: "pocketMoney"
        });
        // console.log(data);
        if (data.code == 200 || this.amount == 0) {
          this.handlePrize(index);
        } else {
          this.$toast.fail(`${data.msg}`);
        }
      } catch (error) {
        console.error(error);
      }
    }, 5000),
    handlePrize(index) {
      let that = this;
      let amountList = this.amountList;
      let posLeft = this.posLeft;
      posLeft.splice(index, 0, "");
      amountList.splice(index, 0, 0);
      let cjList = this.cjList;
      cjList.map((v, i) => {
        // v.flop = '';
        v.animt = "";
        v.left = posLeft[i];
        if (i == index) {
          v.move = `selectMove${index + 1}`;
          v.amount = this.amount.toFixed(2);
        } else {
          v.amount = amountList[i].toFixed(2);
          v.move = `move${i + 1}`;
          v.left = this.posLeft[i];
        }
      });
      this.implement = 1;
      that.isNormal = false;
      setTimeout(function() {
        that.really = index + 1;
        that.cjList = cjList;
      }, 1000);
      setTimeout(function() {
        that.flopStatus = true;
      }, 1500);
    },
    async getBarCode(id) {
      let self = this;
      try {
        const { data } = await this.$api.getBarCode({ id: id });
        if (data.code === 200) {
          // console.log(res)
          let {bannerUrl, amount, amounts} = data.root;
          this.amount = amount;
          this.amountList = amounts;
          this.location(id);
          if(bannerUrl && bannerUrl != ''){
            this.imgShow = true;
            this.bannerUrl = bannerUrl;
            setTimeout(()=>{
              this.imgShow = false;
            }, 4000)
          }
        } else {
          // this.$toast.fail(`${data.msg}`)
          Dialog.alert({
            message: `${data.msg}`
          }).then(() => {
            self.navToMain();
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateAddress(par) {//更新扫码位置
      try {
        const {data} = this.$api.updateAddress(par)
        // console.log(data)
        if(data.code == 200){
          // Dialog.alert({
          //   message: `成功`
          // }).then(() => {
          //   });
        } else {
          // Dialog.alert({
          //   message: `${data.msg}`
          // }).then(() => {
          //   });
        }
      } catch (error) {
        console.error(error);
      }
    },
    navToMain() {
      this.$router.replace({ path: "/main/center" });
    },
    navToAbout() {
      this.$router.replace({ path: "/main/about" });
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
#map-container {
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
}
.container {
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #B30000 0%, #5B030C 100%);
  .bg-yun-left {
    z-index: 1;
    top: 0;
    left: 0;
    position: fixed;
    width: 161px;
    height: 357px;
    background: url("../assets/newImg/bg-yun-left.png") repeat;
    background-size: cover;
  }
  .bg-yun-right {
    z-index: 1;
    top: 0;
    right: 0;
    position: fixed;
    width: 113px;
    height: 300px;
    background: url("../assets/newImg/bg-yun-right.png") repeat;
    background-size: cover;
  }
  .bg-top {
    z-index: 1;
    top: 22%;
    right: 0;
    position: fixed;
    width: 100%;
    height: 148px;
    background: url("../assets/newImg/bg-top.png") repeat;
    background-size: cover;
  }
  .bg-center {
    z-index: 1;
    top: 50%;
    right: 0;
    position: fixed;
    width: 100%;
    height: 228px;
    background: url("../assets/newImg/bg-center.png") repeat;
    background-size: cover;
  }
  .bg-bottom {
    z-index: 1;
    bottom: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 134px;
    background: url("../assets/newImg/bg-bottom.png") repeat;
    background-size: cover;
  }
  .bg-shanzi {
    z-index: 1;
    bottom: 6%;
    right: 34px;
    position: fixed;
    width: 344px;
    height: 70px;
    background: url("../assets/newImg/bg-shanzi.png") repeat;
    background-size: cover;
  }

  .bg-shade {
    position: fixed;
    left: 0;
    z-index: 2;
    top: 121px;
    width: 100%;
    height: 414px;
    background: url("../assets/images/bg-shade.png") repeat;
    background-size: cover;
    animation: spin 10s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
      transition: all 10s;
    }
  }
  .main-con {
    position: fixed;
    top: 2%;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .logo {
      // margin-top: 22px;
      width: 62px;
      height: 93px;
      background: url("../assets/newImg/logo.png") no-repeat;
      background-size: cover;
    }
    .big-tit {
      margin-top: -15%;
      width: 395px;
      height: 165px;
      background: url("../assets/newImg/big-tit.png") no-repeat;
      background-size: cover;
    }
    .small-tit {
      margin-top: -12%;
      width: 130px;
      height: 38px;
      background: url("../assets/newImg/little-tit.png") no-repeat;
      background-size: cover;
    }
    .small-line {
      width: 292px;
      height: 20px;
    }
    .cj-wrapper {
      // margin-top: 14px;
      width: 100%;
      height: 350px;
      // flex: 1;
      position: relative;
    }
    .cj-hide {
      width: 100%;
      height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #f5a623;
    }
    .btn-intro {
      margin-top: 2px;
      width: 132px;
      height: 32px;
      background: url("../assets/newImg/btn-intro.png") no-repeat;
      background-size: cover;
    }
    .btn-look {
      // margin-bottom: 18px;
      width: 255px;
      height: 70px;
      background: url("../assets/newImg/btn-look.png") no-repeat;
      background-size: contain;
    }
  }
  .cj-card {
    position: absolute;
    top: 0px;
    width: 116px;
    height: 170px;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background: url("../assets/newImg/bg-hong.png") no-repeat;
    // background-size: cover;
    .btn-click {
      margin-top: 5px;
      margin-right: 6px;
      width: 51px;
      height: 51px;
      background: url("../assets/newImg/btn-kai.png") no-repeat;
      background-size: cover;
      animation: breathe 1.5s infinite;
    }
    .cj-text {
      width: 80px;
      height: 80px;
      margin-top: 7px;
      margin-right: 6px;
    }
    .cj-end {
      width: 94px;
      height: 138px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background: url("../assets/newImg/zj.png") center no-repeat;
      background-size: contain;
      margin-bottom: 20px;
      // background-position: 20px 35px;
    }
    .cj-zero {
      margin-bottom: 0px;
      background: url("../assets/images/xxhg.png") no-repeat;
      background-size: 100%;
    }
    .cj-other {
      // width: 100%;
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .zj-count {
      padding-left: 0px;
      margin-bottom: 10px;
      position: relative;
      font-family: LiGothicMed;
      font-size: 30px;
      color: #ffe7e7;
    }
    
    .mar-bt {
      margin-bottom: 0;
    }
  }
  @keyframes breathe {
    0% {
      transform: scale(0.88);
    }
    50% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(0.88);
    }
  }
  .isZj {
    justify-content: center;
  }
  .cinton1 {
    top: 0px;
    left: 83px;
    // transform: rotate(-6deg);
    // .cj-text {
    //   background: url("../assets/newImg/gong.png") no-repeat;
    //   background-size: cover;
    // }
    background: url("../assets/newImg/gong1.png") no-repeat;
    background-size: cover;
    animation: cinton1 alternate linear 2 1s;
  }
  @keyframes cinton1 {
    from {
      top: 0px;
      left: 83px;
      transform: rotate(0deg);
    }
    to {
      top: 55px;
      left: 153px;
      transform: rotate(6deg);
    }
  }
  .cinton2 {
    top: 0px;
    left: 231px;
    // transform: rotate(6deg);
    // .cj-text {
    //   background: url("../assets/newImg/xi.png") no-repeat;
    //   background-size: cover;
    // }
    background: url("../assets/newImg/xi1.png") no-repeat;
    background-size: cover;
    animation: cinton2 alternate linear 2 1s;
  }
  @keyframes cinton2 {
    from {
      top: 0px;
      left: 231px;
      transform: rotate(0deg);
    }
    to {
      top: 55px;
      left: 153px;
      transform: rotate(6deg);
    }
  }
  .cinton3 {
    top: 182px;
    left: 83px;
    // transform: rotate(-6deg);
    // .cj-text {
    //   background: url("../assets/newImg/fa.png") no-repeat;
    //   background-size: cover;
    // }
    background: url("../assets/newImg/fa1.png") no-repeat;
    background-size: cover;
    animation: cinton3 alternate linear 2 1s;
  }
  @keyframes cinton3 {
    from {
      top: 182px;
      left: 83px;
      transform: rotate(0deg);
    }
    to {
      top: 55px;
      left: 153px;
      transform: rotate(-6deg);
    }
  }
  .cinton4 {
    top: 182px;
    left: 231px;
    // transform: rotate(6deg);
    // .cj-text {
    //   background: url("../assets/newImg/cai.png") no-repeat;
    //   background-size: cover;
    // }
    background: url("../assets/newImg/cai1.png") no-repeat;
    background-size: cover;
    animation: cinton4 alternate linear 2 1s;
  }
  @keyframes cinton4 {
    from {
      top: 182px;
      left: 231px;
      transform: rotate(0deg);
    }
    to {
      top: 55px;
      left: 153px;
      transform: rotate(6deg);
    }
  }
  .move1 {
    top: 190px;
    transform: scale(0.78);
    // animation: move1 linear 1 1s;
    animation-name: move1, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 5000ms;
    animation-iteration-count: 1, 1;
    background: url("../assets/newImg/gong2.png") no-repeat;
    background-size: cover;
  }
  .select1 {
    background: url("../assets/images/gong2.png") no-repeat;
    background-size: cover;
  }
  @keyframes move1 {
    from {
      top: 0px;
      left: 83px;
      transform: scale(1);
      background: url("../assets/newImg/gong1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: 190px;
      // left: 50px;
      transform: scale(0.78);
      background: url("../assets/newImg/gong2.png") no-repeat;
      background-size: cover;
    }
  }
  .move2 {
    top: 190px;
    transform: scale(0.78);
    // animation: move2 linear 1 1s;
    animation-name: move2, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 2000ms;
    animation-iteration-count: 1, 1;
    background: url("../assets/newImg/xi2.png") no-repeat;
    background-size: cover;
  }
  .select2 {
    background: url("../assets/newImg/xi2.png") no-repeat;
    background-size: cover;
  }
  @keyframes move2 {
    from {
      top: 0px;
      left: 231px;
      transform: scale(1);
      background: url("../assets/newImg/xi1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: 190px;
      transform: scale(0.78);
      background: url("../assets/newImg/xi2.png") no-repeat;
      background-size: cover;
    }
  }
  .move3 {
    top: 190px;
    transform: scale(0.78);
    // animation: move3 linear 1 1s;
    animation-name: move3, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 2000ms;
    animation-iteration-count: 1, 1;
    background: url("../assets/newImg/fa2.png") no-repeat;
    background-size: cover;
  }
  .select3 {
    background: url("../assets/newImg/fa2.png") no-repeat;
    background-size: cover;
  }
  @keyframes move3 {
    from {
      top: 182px;
      left: 83px;
      transform: scale(1);
      background: url("../assets/newImg/fa1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: 190px;
      transform: scale(0.78);
      background: url("../assets/newImg/fa2.png") no-repeat;
      background-size: cover;
    }
  }
  .move4 {
    top: 190px;
    transform: scale(0.78);
    // animation: move4 linear 1 1s;
    animation-name: move4, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 2000ms;
    animation-iteration-count: 1, 1;
    background: url("../assets/newImg/cai2.png") no-repeat;
    background-size: cover;
  }
  .select4 {
    background: url("../assets/newImg/cai2.png") no-repeat;
    background-size: cover;
  }
  @keyframes move4 {
    from {
      top: 190px;
      left: 231px;
      transform: scale(1);
      background: url("../assets/newImg/cai1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: 190px;
      transform: scale(0.78);
      background: url("../assets/newImg/cai2.png") no-repeat;
      background-size: cover;
    }
  }
  .selectMove1 {
    top: -10px;
    left: 158px;
    // animation: selectMove1 linear 1 1s;
    animation-name: selectMove1, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 500ms;
    animation-iteration-count: 1, 1;
    transform: scale(1.46);
    background: url("../assets/newImg/gong2.png") no-repeat;
    background-size: cover;
    .zj-count {
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 8px;
        left: -2px;
        content: "¥";
        font-family: LiGothicMed;
        font-size: 20px;
        color: #ffe7e7;
      }
    }
  }
  @keyframes selectMove1 {
    from {
      top: 0px;
      left: 83px;
      transform: scale(1);
      background: url("../assets/newImg/gong1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: -10px;
      left: 158px;
      transform: scale(1.46);
      background: url("../assets/newImg/gong2.png") no-repeat;
      background-size: cover;
    }
  }
  .selectMove2 {
    top: -10px;
    left: 158px;
    // animation: selectMove2 linear 1 1s;
    animation-name: selectMove2, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 1000ms;
    animation-iteration-count: 1, 1;
    transform: scale(1.46);
    background: url("../assets/newImg/xi2.png") no-repeat;
    background-size: cover;
    .zj-count {
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 8px;
        left: -2px;
        content: "¥";
        font-family: LiGothicMed;
        font-size: 20px;
        color: #ffe7e7;
      }
    }
  }
  @keyframes selectMove2 {
    from {
      top: 0px;
      left: 231px;
      transform: scale(1);
      background: url("../assets/newImg/xi1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: -10px;
      left: 158px;
      transform: scale(1.46);
      background: url("../assets/newImg/xi2.png") no-repeat;
      background-size: cover;
    }
  }
  .selectMove3 {
    top: -10px;
    left: 158px;
    // animation: selectMove3 linear 1 1s;
    animation-name: selectMove3, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 1000ms;
    animation-iteration-count: 1, 1;
    transform: scale(1.46);
    background: url("../assets/newImg/fa2.png") no-repeat;
    background-size: cover;
    .zj-count {
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 8px;
        left: -2px;
        content: "¥";
        font-family: LiGothicMed;
        font-size: 20px;
        color: #ffe7e7;
      }
    }
  }
  @keyframes selectMove3 {
    from {
      top: 182px;
      left: 83px;
      transform: scale(1);
      background: url("../assets/newImg/fa1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: -10px;
      left: 158px;
      transform: scale(1.46);
      background: url("../assets/newImg/fa2.png") no-repeat;
      background-size: cover;
    }
  }
  .selectMove4 {
    top: -10px;
    left: 158px;
    // animation: selectMove4 linear 1 1s;
    animation-name: selectMove4, fanzhuan;
    animation-duration: 1000ms, 1000ms;
    animation-timing-function: linear, linear;
    animation-delay: 0, 1000ms;
    animation-iteration-count: 1, 1;
    transform: scale(1.46);
    background: url("../assets/newImg/cai2.png") no-repeat;
    background-size: cover;
    .zj-count {
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 8px;
        left: -2px;
        content: "¥";
        font-family: LiGothicMed;
        font-size: 20px;
        color: #ffe7e7;
      }
    }
  }
  @keyframes selectMove4 {
    from {
      top: 182px;
      left: 231px;
      transform: scale(1);
      background: url("../assets/newImg/cai1.png") no-repeat;
      background-size: cover;
    }
    to {
      top: -10px;
      left: 158px;
      transform: scale(1.46);
      background: url("../assets/newImg/cai2.png") no-repeat;
      background-size: cover;
    }
  }
  .left1 {
    left: 55px;
  }
  .left2 {
    left: 161px;
  }
  .left3 {
    left: 267px;
  }
  .animt {
    animation: fanzhuan 2s;
  }
  @keyframes fanzhuan {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(-180deg);
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    position: relative;
    width: 350px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 20px;
    .close {
      top: 10px;
      right: 10px;
      position: absolute;
      width: 20px;
      height: 20px;
      background: url("../assets/images/close.png") no-repeat;
      background-size: 20px 20px;
    }
    .p-intro {
      margin-bottom: 15px;
      width: 159px;
      height: 34px;
      background: url("../assets/images/p-intro.png") no-repeat;
      background-size: cover;
    }
    .p-intro-cont {
      width: 300px;
      font-size: 14px;
      color: #000;
      font-weight: 500;
      p {
        line-height: 28px;
      }
    }
    .p-intro-desc {
      width: 300px;
      text-align: left;
      font-size: 12px;
      color: #8e8e8e;
    }
  }
  .img-block{
    position: relative;
    width: 295px;
    // height: 360px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    .ad-close{
      position: absolute;
      top: 12px;
      right: 14px;
      width: 68px;
      height: 28px;
      background: url('../assets/images/ad-close.png') center no-repeat;
      background-size: 100%;
    }
    .ad-img{
      width: 100%;
      // height: 100%;
      border-radius: 20px;
    }
  }
}
</style>