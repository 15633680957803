export function createPxReplace(pixels, viewportSize, unitPrecision, viewportUnit) {
  // 不用好奇$0, $1是怎么来的，他们是replace第二个参数提供的
  // return function ($0, $1) {
  //   if (!$1) return
  //   var pixels = parseFloat($1)
  //   if (pixels <= minPixelValue) return
  // }
  return toFixed((pixels / viewportSize * 12), unitPrecision) + viewportUnit
}
function toFixed(number, precision) {
  var multiplier = Math.pow(10, precision + 1),
    wholeNumber = Math.floor(number * multiplier)
  return Math.round(wholeNumber / 10) * 10 / multiplier
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export const throttle = (fn, gapTime) => {
  if(gapTime == null || gapTime == undefined) {
    gapTime = 1500
  }

  let _lastTime = null
  return function () {
    let _nowTime = +new Date()
    if(_nowTime - _lastTime > gapTime || !_lastTime){
      fn.apply(this, arguments)
      _lastTime = _nowTime
    }
  }
}